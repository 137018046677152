'use client'
import { useRef, useEffect, useState } from 'react'
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useSession, signOut } from "next-auth/react"
import NextLink from 'next/link'
import { useLocalStorage } from 'react-use'
import GeorgeStreetLinenLogo from '@/assets/icons/george-street-linen-logo.svg'
import { useWindowScroll } from 'react-use'
import { MegaMenuButton, AlertPanel} from '@/components'
import Chevron from '@/assets/icons/chevron.svg'
import SearchIcon from '@/assets/icons/search-icon.svg'
import Moment from 'moment'


interface HeaderProps {
    chrome?: any | null
    menus?: any | null
    theme?: any | null
    lang?: any | null
    uid?:any | null
    menuOpen?:any | null
    setMenuOpen?:any | null
    megaOpen?: any | null
    setAccount?: any | null
    account?: any | null
    setMegaOpen?: any | null
    countryMenu?: any | null
    setCountryMenu?: any | null
    showCart?: any | null
    setShowCart?: any | null
    setShowSearch?: any | null
    showSearch?: any | null
    alerts?: any | null
}

const Header:React.FC<HeaderProps> = ({chrome, theme, menus, lang, menuOpen, uid, setMenuOpen, megaOpen, setMegaOpen, account, setAccount, countryMenu, setCountryMenu, showCart, setShowCart, setShowSearch, showSearch, alerts}) => {

    const router = useRouter();
    const pathname = usePathname();

    const { data: session, status } = useSession()

    const user = session?.user as any;

    const bgRef = useRef(null) as any;
    const shadowRef = useRef(null) as any;
    const {x, y} = useWindowScroll();

    const showCartMenu = ()=>{
        setShowCart(!showCart); 
        document.body.style.overflow="hidden"
    }

    useEffect(()=>{
        if(document.getElementById("mininav")) {
            const nav = document.getElementById("mininav") as any
            if( y - nav.offsetTop > -20 ) {
                nav.classList.remove("pb-12", "pt-5", "py-5")
                nav.classList.add("hidden", "pb-4", "md:block","fixed", "top-[55px]", "left-[0px]", "right-[0px]", "duration-500", "z-[30]", "bg-offwhite")
            } 
            if(y < 30) {
                nav.classList.add("pb-12", "pt-5")
                nav.classList.remove("hidden", "pb-4", "md:block", "fixed", "top-[55px]", "left-[0px]", "right-[0px]", "duration-500", "z-[30]", "bg-offwhite")
            }
        }
    },[y])

    const [ cartTotal, setCartTotal]= useState(null) as any
    const [ alertPanel, setAlertPanel ] = useState(true)
    const [ local, setLocal] = useLocalStorage('noalert') as any
    const [ isLoaded, setIsLoaded ] = useState(false)

    let small= false;
    let testTheme = theme

    if(y > 100) {
        bgRef.current?.classList.add("bg-offwhite")
        bgRef.current?.classList.add("h-[70px]")
        shadowRef?.current?.classList.add("hidden")
        testTheme = false
        small= true
    } else {
        bgRef.current?.classList.remove("bg-offwhite")
        bgRef.current?.classList.remove("h-[70px]")
        shadowRef?.current?.classList.remove("hidden")
        testTheme = theme
        small= false
    }

    const setSwitchCountry = (country: string, event:any) => {
        event.stopPropagation();
        const savedCartString = localStorage.setItem('savedCart',[] as any);
        const newParam = country;
        const newPath = pathname.replace(`/${lang}`, `/${newParam}`);
        window.open(newPath, "_self");
    }

    const doSignOut = ()=> {
        signOut();
        router.push(`/${lang}/?auth=logged-out`);
    }

    const removeAlertPanel = () => {
        setAlertPanel(false)
        setLocal({time: Moment().add(2, 'days').format("YYYY-MM-DD")})
    }
    
    const searchParams = useSearchParams();
    let authState = searchParams.get("auth");  
    let token = searchParams.get("token");
    let vtoken = searchParams.get("vtoken");

    useEffect(()=>{

        const savedCartString = localStorage.getItem('savedCart');
        const checkCart = savedCartString ? JSON.parse(savedCartString) : [];

        setCartTotal(checkCart?.length)

        switch(authState) {
            case 'reset' :
                setAccount(true)
            break;
            case 'login' :
                setAccount(true)
            break;
            case 'logged-out' :
                setAccount(true)
            break;
            default:
        }

        setIsLoaded(true)
    }, [showCart])


    let showPanel = true as any

    const accountShow =() => {
        document.body.style.overflow="hidden";
        setAccount(true)
    }

    // console.log(chrome)
    return (
        <div className="fixed z-[20] top-0 w-screen">
        {showPanel === true && alertPanel === true && (!local?.time || local?.time < Moment().format('YYYY-MM-DD')) && chrome === true ? 
            <AlertPanel menus={menus} removeAlertPanel={removeAlertPanel} lang={lang} uid={uid} setAlertPanel={setAlertPanel} local={local}/>
        : <></>}
        <MegaMenuButton alertPanel={alertPanel} chrome={chrome} theme={theme} menuOpen={menuOpen} setMenuOpen={setMenuOpen} megaOpen={megaOpen} setMegaOpen={setMegaOpen} local={local}/>
        {theme===true ?
            <div ref={shadowRef} className={(showPanel === true  && alertPanel=== true && (!local?.time || local?.time < Moment().format("YYYY-MM-DD"))  ? "top-0":"top-0") +" absolute left-0 w-screen bg-gradient-to-b from-warmcharcoal/80 via-warmcharcoal/50 to-transparent h-36 -z-10 opacity-70"}></div>
        :<></>}   
        <div className={(showPanel === true  && alertPanel === true && (!local?.time || local?.time < Moment().format("YYYY-MM-DD")) && isLoaded=== true ? "top-[47px]":"top-0") +" grid grid-cols-12 w-screen md:w-full p-5 transition-all duration-300 z-20 gap-0"} ref={bgRef}>   
            <div className="col-span-6 md:col-span-4 lg:col-span-6">
                <NextLink href={"/"+lang} title="George Street Linen - Home"><GeorgeStreetLinenLogo className={"duration-500 transform-all " + (testTheme === true ? "fill-offwhite " : "fill-warmcharcoal ") + (small !== true ? " w-[47px] lg:w-[68px] md:relative lg:top-2 lg:left-[34px] mb-4" : " w-[40px] lg:w-[40px] md:relative lg:top-0 lg:left-[34px] mb-4")} alt="George Street Linen - Home"/></NextLink>
            </div>
            <div className="col-span-6 md:col-span-8 lg:col-span-6 flex justify-end lg:pr-[100px] lg:w-full lg:mt-[2px]">
                <ul className="hidden lg:block">
                    <li className={(testTheme === true ? "text-offwhite " : "text-warmcharcoal ") + "inline-block text-[12px] font-norma ml-[40px] relative cursor-pointer"} onClick={()=>setCountryMenu(!countryMenu)}>
                            <span className="uppercase">{lang}</span> <Chevron className={(testTheme === true ? "stroke-offwhite" : "stroke-warmcharcoal ") +" inline-block ml-1 rotate-180"}/>
                            {countryMenu == true ?
                            <ul className="block absolute -left-2 top-[30px] w-[94px] m-0 bg-offwhite">
                                <li className={(lang ==="nz" ? "font-semibold bg-warmgrey/10" :"") + " text-warmcharcoal block text-[14px] font-norma  border-b-[1px] border-b-warmgrey/30 p-2 cursor-pointer"} onClick={(e)=>setSwitchCountry("nz", e)}>NZ ($NZD)</li>
                                <li className={(lang ==="au" ? "font-semibold bg-warmgrey/10" :"") + " text-warmcharcoal block text-[14px] font-norma border-b-[1px] border-b-warmgrey/30 p-2 cursor-pointer"}  onClick={(e)=>setSwitchCountry("au", e)}>AU ($AUD)</li>
                                <li className={(lang ==="global" ? "font-semibold bg-warmgrey/10" :"") + " text-warmcharcoal block text-[14px] font-norma border-b-[1px] border-b-warmgrey/30 p-2 cursor-pointer"}  onClick={(e)=>setSwitchCountry("global", e)}>US ($USD)</li>
                            </ul>
                            :<></>}
                    </li>
                    {menus.data.header_navigation?.map((item: any, index: number)=>{
                        if(item.link_text === "Login" && user?.uuid) {
                            item.link_text = "Account"
                        }
                        return (
                            <li onClick={()=>{ item.link_text === "Login" || item.link_text === "Account" ?  accountShow() : item.link_text === "Cart" ? showCartMenu() : null}} 
                                className={(testTheme === true ? "text-offwhite " : "text-warmcharcoal ") + "inline-block text-[12px] font-norma ml-[40px] cursor-pointer relative z-20"} key={index}>
                                    {item.link_text === "Login" || item.link_text === "Account" ? 
                                        <span>{item.link_text}</span>
                                    :<></>}
                                    
                                    {item.link_text === "Shop all" ?
                                    <a href={item.link?.uid ? "/"+lang+"/"+item.link.uid : item.link?.url}>{item.link_text}</a>
                                    : <></>}
                                    {item.link_text === "Cart" ?
                                    <span>{item.link_text} {item.link_text=== "Cart" && cartTotal >0 ? "("+cartTotal+")" :""}</span>
                                    :<></>}
                            </li>
                        )
                    })}
                    <li className="inline-block ml-[40px]"><SearchIcon className={(testTheme === true ? "stroke-offwhite " : "stroke-warmcharcoal ") + " w-[15px] h-[15px] inline-block cursor-pointer"} onClick={()=>{setShowSearch(true); document.body.style.overflow="hidden"}}/></li>
                </ul>
            </div>
        </div>
    </div>
    )
}

export default Header
