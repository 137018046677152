'use client'
import { useState, useRef, useEffect} from 'react'
import Image from 'next/image'
import { WishListButton, SharePanel } from '@/components'
import { useWindowScroll,useWindowSize } from 'react-use'
import Arrow from '@/assets/icons/right-arrow.svg'
import Slider from 'react-slick'

interface ProductGalleryProps {
    product?: any | null
    wishlist?: any | null
}

const ProductGallery:React.FC<ProductGalleryProps>  = ({ product, wishlist }) => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const galleryRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef(null) as any
    const { y } = useWindowScroll();
    const { width, height } = useWindowSize();
    const [initialOffset, setInitialOffset] = useState(0);
    const [lockedPosition, setLockedPosition] = useState(0);
    const [releasePosition, setReleasePosition] = useState(0);

    const settings = {
        infinite: true,           
        speed: 350,               
        slidesToShow: 1,          
        slidesToScroll: 1,
        autoplay: true,           
        autoplaySpeed: 5000,    
        cssEase: "linear",   
        pauseOnHover: true,
        swipeToSlide: true,
        arrows: false,
        dots: false,
        beforeChange: (oldIndex: number, newIndex: number) => setCurrentSlide(newIndex)
        } as any


    const handlePreviousClick = () => {
        const newIndex = currentSlide > 0 ? currentSlide - 1 : 0;
        sliderRef.current?.slickGoTo(newIndex);
    };

    const handleNextClick = () => {
        const newIndex = currentSlide < product.productdata.images.length - 1 
            ? currentSlide + 1 
            : product.productdata.images.length - 1;
        sliderRef.current?.slickGoTo(newIndex);
    };

    useEffect(() => {
        const handleScroll = () => {
            const productFilter = document.querySelector('#product_filter') as HTMLElement | null;
            const gallery = galleryRef.current;
    
            if (gallery && productFilter) {
                const galleryBottom = gallery.offsetTop + gallery.clientHeight;
                const viewportHeight = window.innerHeight;
                const topHeight = viewportHeight - gallery.clientHeight - 20;
                const productFilterBottom = productFilter.offsetTop + productFilter.clientHeight;
    
                const y = window.scrollY;

                // Store the initial offset between gallery and product filter
                if (initialOffset === 0) {
                    setInitialOffset(productFilter.offsetTop - gallery.offsetTop);
                }
    
                if(y > 10) {
                    // When scrolling down and the gallery bottom reaches 20px above the viewport bottom
                    if (y + viewportHeight - 20 >= galleryBottom && lockedPosition === 0) {
                        setLockedPosition(y);
                        gallery.classList.add("lg:fixed");
                        gallery.classList.remove("lg:absolute", "lg:static");
                        gallery.style.top = `${topHeight}px`;
                        // console.log('Setting fixed position with top:', topHeight);
                    }
        
                    // When reaching the product filter bottom
                    if (y + viewportHeight > productFilterBottom && releasePosition === 0) {
                        setReleasePosition(y);
                        gallery.classList.remove("lg:fixed", "lg:static");
                        gallery.classList.add("lg:absolute");
                        gallery.style.top = `${productFilterBottom - gallery.clientHeight}px`;
                        // console.log('Setting absolute position with top:', `${productFilterBottom - gallery.clientHeight}px`);
                    }
        
                    // Ensure the gallery is locked at the top when scrolling back up and it touches the top of the viewport
                    if (y <= gallery.offsetTop && lockedPosition !== 0) {
                        gallery.classList.add("lg:fixed");
                        gallery.classList.remove("lg:absolute", "lg:static");
                        gallery.style.top = '0px';
                    }
        
                    // Handling the case when gallery should be set to absolute based on scroll position
                    if (y + viewportHeight > productFilterBottom) {
                        gallery.classList.remove("lg:fixed", "lg:static");
                        gallery.classList.add("lg:absolute");
                        gallery.style.top = `${productFilterBottom - gallery.clientHeight}px`;
                        // console.log('Re-setting absolute position with top:', `${productFilterBottom - gallery.clientHeight}px`);
                    }
                } else {
                    gallery.classList.remove("lg:absolute","lg:fixed");
                    gallery.classList.add("lg:static");
                    gallery.style.top = '0px';
                    setLockedPosition(0);
                    setReleasePosition(0);
                }
                
            }
        };
    
        const handleResize = () => {
            // Reset the locked and release positions on resize
            setInitialOffset(0);
            setLockedPosition(0);
            setReleasePosition(0);
            handleScroll(); // Ensure position is recalculated on resize
        };
    
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [y, width, height, initialOffset, lockedPosition, releasePosition]);
    
    return (
        <section className="w-screen lg:w-full lg:max-w-[56.5vw]" ref={galleryRef}>
            {product.productdata.images.length > 1 ?
            <Slider {...settings} ref={sliderRef}>
                {product.productdata.images?.map((item: any, index: number) => (
                    <div className="cursor-pointer" key={index}>
                        <Image 
                            src={item.link} 
                            alt={product.name ? product.name : ""} 
                            width={800} 
                            height={800} 
                            className="object-cover w-full aspect-square" 
                        />
                    </div>
                ))}
            </Slider> : 
            <div className="cursor-pointer">
                <Image 
                    src={product.productdata.images[0].link} 
                    alt={product.name ? product.name : ""} 
                    width={800} 
                    height={800} 
                    className="object-cover w-full aspect-square" 
                />
            </div>
            }
            <div className="relative w-full mt-2 lg:mb-16 pl-4 lg:pl-0 lg:text-center">
                {product?.productdata?.images?.length > 1 ? (
                    <p className="inline-block text-[12px] leading-[18px]">
                        <Arrow 
                            className="inline-block mr-3 relative -top-[2px] cursor-pointer stroke-warmcharcoal" 
                            onClick={handlePreviousClick} 
                        />
                        {currentSlide + 1} / {product.productdata.images?.length} 
                        <Arrow 
                            className="inline-block ml-3 rotate-180 relative -top-[2px] cursor-pointer stroke-warmcharcoal" 
                            onClick={handleNextClick} 
                        />
                    </p>
                ) : null}
                <div className="absolute right-12 lg:hidden top-[2px] text-[14px] font-norma inline w-full">
                    <SharePanel image={product?.productdata?.images[0]?.link} />
                </div>
                <div className="absolute right-4 lg:right-0 top-[6px]">
                    <WishListButton productId={product.productdata?.id} wishlist={wishlist} showText={true} />
                </div>
            </div>
            {product.productdata.images.length > 1 ? (
                <div className="my-5 w-full h-[15vw] hidden lg:block">
                    <div className="grid grid-cols-12 gap-x-4">
                        {product.productdata.images?.map((item: any, index: number) => (
                            <div 
                                className={(product.productdata.images.length > 3 ? "col-span-3" : "col-span-4") + " cursor-pointer"} 
                                key={index} 
                                onClick={() => sliderRef.current?.slickGoTo(index)}>
                                <Image 
                                    src={item.link} 
                                    alt={product.name ? product.name : ""} 
                                    width={400} 
                                    height={400} 
                                    className="object-cover w-full aspect-square" 
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </section>
    );
}

export default ProductGallery

